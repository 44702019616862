import React from 'react';
import { trackClick } from '../utils/segment';
import {DropButton as GrommetDropButton} from 'grommet';
import { lowercase, snakeCase } from '../utils/string';

// just a wrapper around Grommet's Button, but with logging capability
const DropButton = ({
    onClick, 
    event,
    ...props
}) => {
    const {section: rawSection, element: rawElement, ...attrs} = event || {};
    const element = snakeCase(lowercase(rawElement || ''));
    const section = snakeCase(lowercase(rawSection || ''));
    const onButtonClick = e => {
        trackClick({
            section,
            element,
            ...attrs
        });
        onClick && onClick(e);
    }
    return (
        <GrommetDropButton 
            data-event-section={section || undefined} 
            data-event-element={element || undefined}
            onClick={onButtonClick}
            {...props} />
    );
} 

export default DropButton;