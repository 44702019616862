import React from 'react';
import { Box, Image, RadioButtonGroup, Text, TextInput } from "grommet";
import { normalizeColor } from "grommet/utils"
import styled from "styled-components";
import SimpleDataTableComponent from '../components/SimpleDataTable';
import {
    faCircle
} from '@fortawesome/pro-light-svg-icons'
import FontAwesomeIcon from '../components/FontAwesomeIcon';
import {
    ICON_WIDTH_DEFAULT,
    ICON_WIDTH_LARGE,
    ICON_WIDTH_MEDIUM,
    ICON_WIDTH_SMALL,
    ICON_WIDTH_XLARGE,
    ICON_WIDTH_XSMALL,
    ICON_WIDTH_XXLARGE,
    ICON_WIDTH_XXSMALL,
    ICON_WIDTH_XXXSMALL
} from "../constants/Dimensions";
import Button from '../components/Button';
import DropButton from '../components/DropButton';

export const normalizeTheme = (theme) => {
    return {
        ...theme,
        brandColor: normalizeColor(theme.global.colors.brand, theme),
        borderColor: normalizeColor(theme.global.colors.border, theme),
        activeColor: normalizeColor(theme.global.colors.active, theme),
        textColor: normalizeColor(theme.global.colors.text, theme),
        focusColor: normalizeColor(theme.global.colors.focus, theme),
        textXweakColor: normalizeColor(theme.global.colors['text-xweak'], theme),
        textHighContrastColor: normalizeColor(theme.global.colors['text-high-contrast'], theme),
        frontgroundColor: normalizeColor(theme.global.colors['background-front'], theme),
        backgroundColor: normalizeColor(theme.global.colors.background, theme),
        fontFamily: theme.global.font.family
    }
};

export const PositionRelativeBox = styled(Box)`
    position: relative;
    @media print {
        display: block !important;
    }
    ${props => props.styles ? props.styles : ''}
`;
export const PositionAbsoluteBox = styled(Box)`
    position: absolute;
    ${props => props.height ? `height: ${props.height};` : ''}
    ${props => props.right ? `right: ${props.right};` : ''}
    ${props => props.top ? `top: ${props.top};` : ''}
    ${props => props.left ? `left: ${props.left};` : ''}
    ${props => props.bottom ? `bottom: ${props.bottom};` : ''}
    ${props => props.transform ? props.transform : ''}
    ${props => props.additionalStyle ? props.additionalStyle : ''}
`;

export const PositionFixedBox = styled(Box)`
    position: fixed;
    ${props => props.height ? `height: ${props.height};` : ''}
    ${props => props.right ? `right: ${props.right};` : ''}
    ${props => props.top ? `top: ${props.top};` : ''}
    ${props => props.left ? `left: ${props.left};` : ''}
    ${props => props.bottom ? `bottom: ${props.bottom};` : ''}
    ${props => props.transform ? props.transform : ''}
    ${props => props.additionalStyle ? props.additionalStyle : ''}
`;


export const PositionStickyBox = styled(Box)`
    position: sticky;
    @media print {
        display: block !important;
    }
    ${props => props.top ? `top: 0;` : ''}
    ${props => props.bottom ? `bottom: 0;` : ''}
    ${props => props.additionalStyle || ''}
`;


export const EaseInOnHoverBox = styled(Box)`
    .show-on-hover {
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        .show-on-hover {
            transition: all ease-in 0.3s;
            display: initial;
            opacity: 1;
            visibility: visible;
        }
        .hide-on-hover {
            visibility: hidden;
        }
    }
    ${props => props.styles ? props.styles : ''}
`;

export const ShowOnHoverBox = styled(Box)`
    .show-on-hover {
        display: none;
    }
    &:hover .show-on-hover {
        transition: all ease-in 0.5s;
        display: initial;
    }
    &:hover .hide-on-hover {
        transition: all ease-in 0.5s;
        display: none;
    }
`;
export const StyledLink = styled(Box)`
    button:hover, button {
        background-color: transparent;
    }
    &:hover {
        background-color: ${props => normalizeColor(props.theme.global.colors.active, props.theme)};
    }
`;
export const DanglingContainer = styled.div`
    position: relative;
    > div {
        position: absolute;
        right: 0;
        transform: translate(100%, 0);
    }
`;

export const ThickLeftBorderContainer = styled(Box)`
    border-color: ${props => props.borderColor} ${props => props.borderColor} ${props => props.borderColor} ${props => props.accentColor};
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const FixedWidthText = styled(Text)`
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
`;

export const TextBasedIcon = ({ text, border, highlight, color, size, fixedWidth, ...rest }) => {
    const TextComponent = fixedWidth ? FixedWidthText : Text;
    return (
        <Box
            {...(highlight || border ? {
                round:"50%",
                height: `36px`,
                width: `36px`,
                ...(highlight && typeof highlight === "string"? { background: highlight } : {}),
                pad: {top: '5px'},
                margin: {vertical: 'xsmall'},
                align: "center",
                ...(border ? { border } : {})
            } : {})}
            {...rest}>
            <TextComponent size={size || "xlarge"} color={color} weight={600}>{text}</TextComponent>
        </Box>
    )
};

export const Dot = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 50%;
    ${props => props?.borderColor ? `border: 1px solid ${props.borderColor};` : ""}
    background-color: ${props => props.color};
`

export const CheckboxWrapper = styled(Box)`
>label {
    padding: 0;
}

input[type="checkbox"]:checked + div {
    border: none;
}
`;

export const DisplayNoneBox = styled(Box)`
    display: none;
`;
export const DisplayInlineBlockBox = styled(Box)`
    display: inline-block;
    ${props => props.additionalStyle ? props.additionalStyle : ''}
`;

// The "v" extension button next to primary CTA
export const ExtensionButton = styled(DropButton)`
    padding: 10px 4px;
    border-radius: 0 4px 4px 0;
`;

export const DisplayInlineBlockDropButton = styled(DropButton)`
    display: inline-block;
`;

export const EnhancedDropButton = styled(DropButton)`
    ${props => props.styles ? props.styles : ''}
`;


export const MainButtonWithExtension = styled(Button)`
    border-radius: 4px 0 0 4px;
    ${props => props.hideRightBorder ? 'border-right: 0;' : ''}
`;

export const PrintableSimpleDataTable = styled(SimpleDataTableComponent)`
    @media print {
        width: 100%;
    }
`;

export const DarkenOnHoverBox = styled(Box)`
    &:hover {
        background-color: ${props => `${normalizeColor(props.background, props.theme)}44`};
    }
`;

export const DarkModeCompatibleImage = styled(Image)`
    ${props => props.dark ? '' : ''}
`; // filter: invert(1);

export const ImageWithMinMaxDimension = styled(Image)`
    ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
    ${props => props.minWidth ? `min-width: ${props.minWidth};` : ''}
    ${props => props.maxHeight ? `max-height: ${props.maxHeight};` : ''}
    ${props => props.minHeight ? `min-height: ${props.minHeight};` : ''}
`;

export const EnhancedTextInput = styled(TextInput)`
    ${props => props.styles ? props.styles : ''}
`;

export const EnhancedBox = styled(Box)`
    ${props => props.styles ? props.styles : ''}
`;

export const UnderlinedText = styled(Text)`
    text-decoration: underline;
`;

export const EnhancedText = styled(Text)`
    ${props => props.styles ? props.styles : ''}
`;

export const RadioButtonGroupWithFullWidthChild = styled(RadioButtonGroup)`
> label {
    width: 100%;
    > div {
        width: 100%;
    }
}
`;

export const EnhancedButton = styled(Button)`
    ${props => props.styles ? props.styles : ''}
`;