import Honeybadger from '@honeybadger-io/js';
import { getSession } from 'next-auth/react';

// do not log 401 and 404 errors to honeybadger
const isWhiteListError = (err) => {
    const whitelistedCodes = ['401','404','422','403'];
    // Catch errors in Got OR the return value from buildErrors
    const statusCode = err?.statusCode || err?.status
    return statusCode && (whitelistedCodes.includes(statusCode.toString()));
}

// Handle errors that are received from the Rails Backend
export const buildErrors = (e, fnName, context) => {
    const {
        statusCode: status,
        body: data,
    } = e.response || {};

    if (e.message?.includes('Cannot read property \'accessToken\' of null')) {
        return [
            401,
            {
                type: 'error',
                status: 401,
                message: "Session expired. Please sign in."
            }
        ];
    }


    if(!e.request && !e.response){ // No request was made and no response from BE (Error in intermediate step)
        return [
            500,
            {
                type: 'error',
                status: 500,
                message: "Something went wrong. Please try again and contact us at help@streamlinedpayments.com if the problem continues."
            }
        ]
    }

    if (!status && e.request) { // BE is down (only show 503 if request was made and there is no response)
        return [
            503,
            {
                type: 'error',
                status: 503,
                message: "The application is temporarily unavailable."
            }
        ];
    }
    if (status === 404) {
        return [
            status,
            {
                type: 'error',
                status: status,
                message: e.response.body?.message
            }
        ];
    }

    if (data && data?.errors) {
        const errors = data.errors;
        errors.map((error) => {
            console.error(error);
            console.error(error.statusCode, error.statusMessage, fnName);
            error.type = 'error';
            if (error.hasOwnProperty('messages')) {
                error.message = error.messages;
            }
        });
        !isWhiteListError(errors[0]) && Honeybadger.notify(errors[0], {
            context: {
                ...context,
                fnName,
                error: {
                    status: errors[0]?.statusCode,
                    statusText: errors[0]?.statusMessage
                },
                fullError: data
            }
        });
        return [
            status,
            errors[0]
        ];
    }

    if (data && data?.error) {
        const error = data.error;
        console.error(e.response);
        console.error(e.response.statusCode, e.response.statusMessage, fnName);
        !isWhiteListError(e.response) && Honeybadger.notify(e.response.statusCode, {
            context: {
                ...context,
                fnName,
                error: {
                    status: e.response?.statusCode,
                    statusText: e.response?.statusMessage
                },
                fullError: data
            }
        });
        return [
            status,
            {
                type: 'error',
                status: status,
                message: error,
            }
        ];
    }
};

// Process and display errors on the frontend UI
export const handleErrors = async (response) => {
    if (!response.ok) {
        try{
            window.analytics.track(`Error ${response?.statusText}`,{
                ...response
            })
        }catch{
            console.error("Segment Error");
        }
        !isWhiteListError(response) && Honeybadger.notify(response.error, {
            context: {
                error: {
                    status: response?.status,
                    statusText: response?.statusText
                },
                response: response
            },
        });
        if (window.location.href.indexOf("/payments") > -1) {
            return response.json().then(Promise.reject.bind(Promise));
        } else{
            if (response?.status === 404) {
                window.location.href = "/404";
            }
            if (response?.status === 503) {
                window.location.href = "/503";
            }
        }
        return response.json().then(Promise.reject.bind(Promise));
    }
    return response.json();
};

// Process and display errors on the NodeJS layer
export const handleServerSideErrors = async (response) => {
    const [status, data] = await response;
    if (status >= 300) { // error case
        !isWhiteListError({status}) && Honeybadger.notify(response.error, {
            context: {
                error: {
                    status,
                    statusText: data?.message || ''
                },
            },
        });
        return [null, data];
    }
    return [data, null];
};

export const handleFileDownloadErrors = async (response) => {
    if (!response.ok) {
        !isWhiteListError(response) && Honeybadger.notify(response.error, {
            context: {
                error: {
                    status: response?.status,
                    statusText: response?.statusText
                },
            },
        });
        return response.blob().then(Promise.reject.bind(Promise));
    }
    return response.blob();
};


export const silentFetcher = (url) => fetch(url).then(handleErrors);