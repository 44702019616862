/**
 * SimpleDataTable - wrapper around grommet data table. Currently only used for itemized list in invoice view.
 */
import React from "react";
import {DataTable as GrommetDataTable, ThemeContext} from "grommet";
import styled, {css} from "styled-components";

const DataTable = styled(GrommetDataTable)`
    thead span, 
    thead div {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700 !important;
        ${(props) => props.fontSize && css`
            font-size: ${(props.theme.text.xsmall.size)} !important;
        `}
    }
`;

const SimpleDataTableComponent = (props) => {
    return (<ThemeContext.Consumer>
        { (theme) => (<DataTable fontSize={theme.global.font.size} {...props} />)}
    </ThemeContext.Consumer>);
};

export default SimpleDataTableComponent;
